import React, { useState } from 'react';
import { Search, Clock, ExternalLink } from 'lucide-react';
import { useLanguage } from '../LanguageContext';


interface Article {
  id: number;
  title: string;
  excerpt: string;
  date: string;
  category: string;
  readingTime: number;
  size: 'small' | 'medium' | 'large';
  link: string;
  image?: string;
}


const Articles: React.FC = () => {
  const { t } = useLanguage(); // Use the custom hook
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState<"date" | "readingTime">("date");

  const articles: Article[] = [
    {
      id: 1,
      title: t("articlePage.articles.LLMDSL.title"),
      excerpt: t("articlePage.articles.LLMDSL.excerpt"),
      date: "2024-09-01",
      category: t("articlePage.articles.category.science"),
      readingTime: 20,
      size: "medium",
      link: "https://chemrxiv.org/engage/chemrxiv/article-details/66d20058a4e53c487628f63b",
      image: "./images/developing_llms.png"
    },
    {
      id: 2,
      title: t("articlePage.articles.alLabelling.title"),
      excerpt: t("articlePage.articles.alLabelling.excerpt"),
      date: "2021-10-03",
      category: t("articlePage.articles.category.science"),
      readingTime: 30,
      size: "medium",
      link: "https://arxiv.org/abs/2109.04847"
    },
    // {
    //   id: 3,
    //   title: "5G Technology: Revolutionizing Communication",
    //   excerpt: "A brief overview of how 5G is changing the landscape of mobile networks.",
    //   date: "2020-07-30",
    //   category: t("articlePage.articles.category.medium"),
    //   readingTime: 5,
    //   size: "small",
    //   link: ""
    // },
    // Add more articles here...
  ];

  
  const filteredAndSortedArticles = articles
    .filter(article =>
      article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      article.excerpt.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortBy === "date") {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      } else {
        return a.readingTime - b.readingTime;
      }
    });

  return (
    <div className="bg-gray-50 dark:bg-gray-900 py-[6rem]">
      <div className="container mx-auto px-4 transition-colors duration-300">
        <div className="flex justify-between items-center mb-6 gap-2 md:gap-0">
          <div className="relative">
            <input
              type="text"
              placeholder={t('articlePage.searchPlaceholder')}
              className="w-[100%] pl-10 pr-4 py-2 border rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 transition-colors duration-300"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
          </div>
          <select
            className="w-[50%] md:w-auto p-2 border rounded-lg bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100 transition-colors duration-300"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value as "date" | "readingTime")}
          >
            <option value="date">{t('articlePage.sort.date')}</option>
            <option value="readingTime">{t('articlePage.sort.readingTime')}</option>
          </select>
        </div>

        <div className="flex flex-col gap-4">
          {filteredAndSortedArticles.map((article) => (
            <div key={article.id} className="bg-gray-80 flex flex-col flex-grow p-bg-white p-6 bg-white dark:bg-gray-700 rounded-lg shadow-md hover:shadow-lg overflow-hidden flex flex-col transition-colors duration-300">
              <div className="flex justify-between items-start mb-2">
                <a href={article.link} target="_blank" rel="noopener noreferrer" className="group">
                  <h2 className={`text-xl md:text-2xl font-bold group-hover:underline ${
                    article.category === 'Medium' ? 'text-blue-600 dark:text-blue-400' : 'text-green-600 dark:text-green-400'
                  }`}>
                    {article.title}
                    <ExternalLink size={16} className="inline-block ml-2 mb-1" />
                  </h2>
                </a>
                <span className={`px-2 py-1 rounded-full text-xs font-semibold ${
                  article.category === 'Medium' 
                    ? 'bg-blue-100 text-blue-800 dark:bg-blue-900 dark:text-blue-200' 
                    : 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                }`}>
                  {article.category}
                </span>
              </div>
              <p className="text-gray-600 dark:text-gray-300 mb-4 flex-grow transition-colors duration-300">{article.excerpt}</p>
              {article.image && (
                <img src={article.image} alt={article.title} loading="lazy" className="dark:filter dark:invert-[.5] w-full h-64 object-contain mb-4" />
              )}
              <div className="flex justify-between items-center text-sm text-gray-500 dark:text-gray-400 transition-colors duration-300">
                <span>{new Date(article.date).toLocaleDateString()}</span>
                <div className="flex items-center">
                  <Clock size={16} className="mr-1" />
                  <span>{article.readingTime} {t("articlePage.timeRead")}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Articles;