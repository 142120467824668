import React, { useState, ReactNode } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface ListItem {
  title: string;
  content: ReactNode;
}

interface ExpandableListItemProps {
  title: string;
  content: ReactNode;
}

interface ExpandableListProps {
  items: ListItem[];
}

const ExpandableListItem: React.FC<ExpandableListItemProps> = ({ title, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className="">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="flex items-center justify-between w-full p-4 bg-white dark:bg-gray-700 rounded-lg shadow-md hover:shadow-lg transition-all duration-300"
      >
        <span className="text-lg font-semibold text-gray-800 dark:text-white">{title}</span>
        {isExpanded ? <ChevronUp className="w-6 h-6" /> : <ChevronDown className="w-6 h-6" />}
      </button>
      {isExpanded && (
        <div className="mt-2 p-4 bg-gray-100 dark:bg-gray-600 rounded-lg">
          <div className="text-gray-700 dark:text-gray-200">{content}</div>
        </div>
      )}
    </div>
  );
};

const ExpandableList: React.FC<ExpandableListProps> = ({ items }) => {
  return (
    <div className="flex mt-2 gap-y-2 justify-between flex-col">
      {items.map((item, index) => (
        <ExpandableListItem key={index} title={item.title} content={item.content} />
      ))}
    </div>
  );
};

export default ExpandableList;