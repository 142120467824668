import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LanguageProvider } from './LanguageContext';
import HomePage from './pages/HomePage';
import Articles from './pages/Articles';
import Experience from './pages/Experience';
import Navbar from './components/NavBar';
import Footer from './components/Footer';
import ScrollToTop from './functions/ScrollToTop';


function App() {

  return (
    <LanguageProvider>
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/experience" element={<Experience />} />
        </Routes>
        <Footer />
      </Router>
    </LanguageProvider>

  );
}

export default App;