import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {Sun, Moon, Github, Linkedin, ChevronDown, Globe, ChevronUp } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedium } from '@fortawesome/free-brands-svg-icons';
import { useLanguage } from '../LanguageContext';
import { Language } from '../translations'


const Navbar: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const location = useLocation();
  const { t, setLanguage, language } = useLanguage(); // Use the custom hook

  const languages: Language[] = ['en', 'nl'];


  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [darkMode]);

  const navItems = [
    { name: t('navbar.about'), path: '/' },
    { name: t('navbar.articles'), path: '/articles' },
    { name: t('navbar.experience'), path: '/experience' },
  ];

  const socialIcons = [
    { Icon: Github, link: 'https://github.com/Pieter-Jacobs' },
    { Icon: Linkedin, link: 'https://www.linkedin.com/in/pieter-jacobs-13a3321a3/' },
  ];


  return (
    <nav className={`fixed top-0 left-0 w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-white/80 dark:bg-gray-800/80 backdrop-blur-md shadow-md' : 'bg-transparent'
      } ${isOpen ? 'bg-white dark:bg-gray-800' : ''}`}>
      <div className="mx-auto px-2 sm:px-2 md:px-4 lg:px-6">
        <div className="flex md:grid md:grid-cols-[1fr_auto_1fr] items-center h-16 justify-between">
          <div className="flex items-center">
            <Link to="/" className="w-[50px] font-bold text-gray-800 dark:text-white">
              <img src='./images/Logo.png' className="dark:filter dark:invert-[.5]" alt="My Logo" />
            </Link>
          </div>
          <div className="hidden md:block">
            <div className="flex items-baseline space-x-4">
              {navItems.map((item) => (
                <Link
                  key={item.name}
                  to={item.path}
                  className={`relative px-3 py-2 text-sm font-medium ${location.pathname === item.path
                      ? 'text-blue-600 dark:text-blue-400'
                      : 'text-gray-400 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400'
                    } transition-colors duration-300`}
                >
                  {item.name}
                  {location.pathname === item.path && (
                    <span className="absolute bottom-0 left-0 w-full h-0.5 bg-blue-600 dark:bg-blue-400 transform origin-left scale-x-0 transition-transform duration-300 group-hover:scale-x-100" />
                  )}
                </Link>
              ))}
            </div>
          </div>
          <div className='md:hidden' 
            onClick={() => setIsOpen(!isOpen)}>
            {isOpen ?  <ChevronUp/> : <ChevronDown/>}
          </div>
          <div className="hidden md:flex items-center md:space-x-[6px] lg:space-x-4 justify-end">
            {socialIcons.map(({ Icon, link }) => (
              <a key={link} href={link} target="_blank" rel="noopener noreferrer" className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400">
                <Icon className="w-6 h-6 md:w-4 md:h-4 lg:w-5 lg:h-5" />
              </a>
            ))}
            <a key="https://medium.com/@pfjacobs" href="https://medium.com/@pfjacobs" target="_blank" rel="noopener noreferrer" className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400">
              <FontAwesomeIcon icon={faMedium} className='md:mr-2 lg:mr-[0px]' />
            </a>
            <div className="relative">
              <button
                onClick={() => setIsLanguageDropdownOpen(!isLanguageDropdownOpen)}
                className="flex items-center space-x-1 lg:p-2 rounded-full text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700"
              >
                <Globe className="w-6 h-6 md:w-4 md:h-4 lg:w-5 lg:h-5" />
                <span className="ml-1">{language.toUpperCase()}</span>
                <ChevronDown size={16} />
              </button>
              {isLanguageDropdownOpen && (
                <div className="absolute right-0 mt-2 py-2 w-48 bg-white dark:bg-gray-800 rounded-md shadow-xl z-20">
                  {languages.map((lang) => (
                    <button
                      key={lang}
                      className="flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 w-full"
                      onClick={() => {
                        setLanguage(lang);
                        setIsLanguageDropdownOpen(false)
                      }}
                    >
                      <Globe size={16} className="mr-2" />
                      <span className="mr-2">{lang.toUpperCase()}</span>
                      {t(`navbar.languageName.${lang}`)}
                    </button>
                  ))}
                </div>
              )}
            </div>
            <button
              onClick={() => setDarkMode(!darkMode)}
              className="lg:p-2 rounded-full text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700"
            >
              {darkMode ? <Sun size={20} /> : <Moon size={20} />}
            </button>
          </div>
        </div>
      </div>
      {/* Mobile menu */}
      {isOpen && (
        <div className="md:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navItems.map((item) => (
              <Link
                key={item.name}
                to={item.path}
                className={`block px-3 py-2 rounded-md text-base font-medium ${location.pathname === item.path
                    ? 'text-blue-600 dark:text-blue-400 bg-blue-50 dark:bg-blue-900'
                    : 'text-gray-800 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 hover:bg-gray-50 dark:hover:bg-gray-700'
                  }`}
                onClick={() => setIsOpen(false)}
              >
                {item.name}
              </Link>
            ))}
          </div>
          <div className="px-4 py-3 flex justify-between items-center border-t border-gray-200 dark:border-gray-700">
            <div className="flex space-x-4">
              {socialIcons.map(({ Icon, link }) => (
                <a key={link} href={link} target="_blank" rel="noopener noreferrer" className="text-gray-600 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400">
                  <Icon size={20} />
                </a>
              ))}
            </div>
            <div className="flex items-center space-x-2">
              <div className="relative">
                <button
                  onClick={() => setIsLanguageDropdownOpen(!isLanguageDropdownOpen)
                  }
                  className="flex items-center space-x-1 p-2 rounded-full text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700"
                >
                  <Globe size={20} />
                  <span>{language.toUpperCase()}</span>
                  <ChevronDown size={16} />
                </button>
                {isLanguageDropdownOpen && (
                  <div className="absolute right-0 mt-2 py-2 w-48 bg-white dark:bg-gray-800 rounded-md shadow-xl z-20">
                    {languages.map((lang) => (
                      <button
                        key={lang}
                        className="flex items-center px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-700 w-full"
                        onClick={() => {
                          setLanguage(lang)
                          setIsLanguageDropdownOpen(false)
                        }
                        }
                      >
                        <Globe size={16} className="mr-2" />
                        <span className="mr-2">{lang.toUpperCase()}</span>
                        {t(`navbar.languageName.${lang}`)}
                      </button>
                    ))}
                  </div>
                )}
              </div>
              <button
                onClick={() => setDarkMode(!darkMode)}
                className="p-2 rounded-full text-gray-600 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700"
              >
                {darkMode ? <Sun size={20} /> : <Moon size={20} />}
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="h-px bg-gray-200 dark:bg-gray-700 w-full" />
    </nav>
  );
};

export default Navbar;