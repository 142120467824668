import CustomLink from './components/CustomLink';
import React from 'react';

export const translations = {
  en: {
    navbar: {
      about: "About Pieter",
      articles: "My Articles",
      experience: "My Experience",
      languageName: {
        en: "English",
        nl: "Dutch"
      }
    },
    footer: {
      copyright: "Pieter Floris Jacobs. All rights reserved.",
      location: "Amsterdam, the Netherlands"
    },
    homepage: {
      frontpage: {
        title: "Hi I'm Pieter",
        intro: (<>I’m an Artificial Intelligence graduate currently working at <CustomLink to='https://onethird.io/?__hstc=177026938.db2eff16bd6670e61ed4c56d387ea301.1723543355164.1724930932484.1726171358501.4&__hssc=177026938.1.1726171358501&__hsfp=1350194568'>OneThird</CustomLink> to reduce worldwide food waste.
          Most of my time is spent on this mission, but since I can't sit still, I'm always working on various side-projects.
          On this website, you'll find an overview of all the cool things I’m doing, and have done in the past.</>),
        explore: "Explore My Work"
      },
      about: {
        title: "About Me",
        summary: "I'm a creative person with the desire to make a positive impact on other people's lives through solving large real-world problems. I combine my technical expertise in Artificial Intelligence with an entrepreneurial mindset to turn ideas into reality. Once captivated by a project, I become laser-focused to see it through.",
        learnMore: (<>Learn more about me by expanding the below sections or by going over my <CustomLink to="/experience">experience</CustomLink>.</>),
        items: {
          ai: {
            "title": "Artificial Intelligence Enthousiast",
            "content": (
              <div className='flex flex-col gap-y-2'>
                <p>
                  From a young age, I’ve always been a game enthusiast. I played a lot of chess and got into various trading card games over the years.
                  The complex strategies involved, paired with tough decision-making, always intrigued me. I found it extremely rewarding to improve and better understand these games.
                  This love for games eventually introduced me to Artificial Intelligence (AI), as various algorithms were being created in this field that aimed to automate decision making in strategy games. 
                  It fascinated me to see that computers could master these games and even consistently beat professional (human) players. Naturally, this various many questions for me:
                </p>
                <ul className='ml-4 list-disc list-inside'>
                  <li>How do these algorithms work?</li>
                  <li>What other complex tasks could AI handle?</li>
                  <li>Are these models truly intelligent, and can human intelligence be replicated?</li>
                </ul>
                <p>
                  Seeking answers, I started studying in AI at the <CustomLink to="https://www.rug.nl/" external>University of Groningen</CustomLink>.
                </p>
                <p>
                  Many of my questions were answered during my BSc. and MSc. in AI, although the question on human intelligence remains great material for discussion.
                  I learned about the fundamental principles of AI and the building blocks behind it. The various fields within AI were covered —from robotics to multi-agent systems to the now well-known machine and deep learning (because yes, these are not the same as AI but are mere parts of it).
                  It was a lot of new information, most of which I found fascinating, especially when I could apply it practically. A lot of the now established models and algorithms were still in its early stages back then, and it’s interesting to look back and reminisce about how few people really understood what my field was all about.
                </p>
                <p>
                  Nowadays, things are quite different. AI has become a buzzword, and everyone seems eager to jump on the hype. While many now think AI equals Large Language Models (LLMs), people at least recognize the term. Working with AI has also become far more accessible.
                  Any beginner programmer can, with just a few lines of code, build a neural network or some other complex model and quickly achieve acceptable results. As time goes on, this will only get easier, especially with quick advancements we see in LLMs.
                </p>
                <b>So then what is my added value?</b>
                <p>
                  I believe it lies in having a deep understanding of what’s going on behind the scene and knowing the backgrounds and purposes of the different components of what we call AI. To innovate in the field and properly apply AI, I believe it’s crucial to start from first principles.
                  This also includes understanding how the brain works and which aspects of psychology and philosophy are relevant when simulating human intelligence. These are key to answering some of the big questions we still face today.
                </p>
                <p>
                  Just to name a few:
                </p>
                <ul className='ml-4 list-disc list-inside'>
                  <li>What role does consciousness play in AI, or god forbid, what even is consciousness?</li>
                  <li>How can we develop biologically plausible AI?</li>
                  <li>How do humans create and learn language?</li>
                  <li>How can we ensure AI safety?</li>
                </ul>
                <p>
                  My goal is to regularly write about these kinds of questions and share my thoughts with you through online content. Not only because I enjoy sharing my knowledge, but also because it helps keep me sharp!
                </p>
              </div>
            ),
          },
          "focus": {
            "title": "Focus on Impact",
            "content": (

              <div className='flex flex-col gap-y-2'>
                <p>
                  The projects I'm interested in tend to be quite ambitious. I am motivated by ideas that
                  have the potential to have a significant positive influence on the world, as I find it rewarding when
                  I can help create something that is visibly improving people's daily lives. Whereas I used to believe that people are quite insignificant, and won't be able
                  to change the trajectory of the bigger picture, I have become a bit more of an optimist in recent times.
                </p>
                <p>
                  <b>Because why not try?</b> There are enough examples of small groups of people going from zero to one.
                  I believe that I am bright and that I have some talent, so why wouldn't I be able to do the same? It would be a real shame to waste my time and talent on anything less,
                  especially now that I have the energy and mindset to take on large scale challenges. When I find a project that resonates with me, I fully immerse myself in it.
                  An example of this is a project where I worked on using AI to detect fungal infections in the lungs, but also my current role in which I’m working full-time on using AI to reduce food waste (mainly fresh food!).
                </p>
              </div>
            ),
          },
          "entrepreneurial": {
            "title": "Entrepreneurial Mindset",
            "content":
              (
                <div className='flex flex-col gap-y-2'>
                  <p>
                    I wholeheartedly believe that, in a certain sense, children are still pure. They are fully true to themself, and that one can tell a lot about their character and personality from merely observing them.
                    No, I don't want to start a nature versus nurture debate. I am merely tring to be able to use my younger self as an example of my entrepreneurial mindset. From a young age, I was always
                    busy with ideating, making things, and selling them (this was very important to me).
                  </p>

                  <p>
                    To examplify, back in primary school I created strategy games that the entire school could participate in.
                    I designed detailed maps of fantasy worlds where students could buy their own land. They could furnish this land with various products that I offered, which I
                    would then neatly draw on their section of the map. <i>But nothing in life comes for free!</i> I decided we needed a currency so that people could pay me and trade with each other;
                    scrap paper became the improvised money. Before long, the economy was booming, with kids trading, building, and—inevitably—waging wars against each other.
                    War, of course, is expensive, so I quickly became a fairly wealthy individual, meaning my mother never had to worry about running out of paper for grocery lists again.
                  </p>

                  <p>
                    This success, along with other side hustles like my cleaning gum trade and my shop of knife-like objects made from ballpoint pens, naturally motivated me to turn more of my ideas into reality.
                    In high school, I flipped various popular sneakers to make some money on the side and even attempted—though unsuccessfully—to create an MMORPG to play with my friends. Moreover, instead of doing a standard final research project, I decided to write a Black Mirror-esque fiction book about a monopolist trading in data (which, in hindsight,
                    turned out to be a rather accurate prediction of today's world). My co-author and I published the book and sold it to anyone who was curious.
                    Seeing people enjoy or benefit from the things I create has always been incredibly rewarding, and that drive has stayed with me throughout the years.
                  </p>

                  <p>
                    When I started my student life, it was time to get a bit more serious: together with a friend from university I founded a company called Omun. Since my programming experience was still
                    fairly limited at the time, we started with simple web development for small and medium-sized enterprises. However, we quickly shifted to creating web apps with
                    AI integrations. This allowed us to apply the theory we learned at our study directly to real-world cases; naturally, this was highly educational. During my minor in entrepreneurship,
                    I applied the insights to improve our company's operations. My interest in entrepreneurship also led to my position at the student-run consultancy firm called <CustomLink to='https://youngadvisorygroup.nl/'>Young Advisory Group</CustomLink>. 
                    Here, I gained valuable experience in managing a larger organization and enjoyed the freedom to pitch ideas.
                    YAG's organizational structure is flat, meaning you could initiate practically anything, as long as you garnered enough support from your colleagues.
                    Some examples of things I did there include restructuring the company's digital framework, organizing a career event and setting up an incubator.
                  </p>
                  <p>
                    In the future, I want to work in organizations where entrepreneurship is a core value, thanks in part to my time at YAG and Omun.
                    To learn more about these experiences, you can click <CustomLink to="/experience">here</CustomLink>.
                  </p>
                </div>

              ),
          },
          "creative": {
            "title": "Creative and Fixated",
            "content":
              (
                <div className='flex flex-col gap-y-2'>
                  <p>
                    Turning thoughts into reality is possibly one of my favorite things to do. I believe that the things you can clearly visualize, you can also make happen.
                    Perhaps that's also why I started programming, and why I initially gravitated towards web development.
                    Being able to see immediate results with every line of code I wrote (unless, of course, when I was trying to center a <i>div</i>) was very rewarding.
                    Moreover, building web applications gave me the ability to create things that were accessible to anyone with an internet connection, which was quite energising to me.
                  </p>
                  <p>
                    After starting with web development, I realized that technology was a great way to express my creativity. I immersed myself in various topics,
                    from quantum computing to AI, and even the controversial world of crypto. I invested a lot of time in building decentralized apps with Solidity.
                    Even though it turns out that decentralization has limited use cases, I loved the process of learning a new technology.
                    Of course, not everything needs to have a practical use case, and I think it's important that some things are simply created for fun.
                    I've been making music from a young age, enjoy writing short fiction, started a humorous podcast with a good friend,
                    and love creating customized games to play with my buddies. These are the kinds of activities that energize me.
                    I'm not afraid to put myself out there and really enjoy creating online content, so it's something I plan to continue doing in the near future.
                  </p>
                  <p>
                    When I find something that resonates with me, I lose myself in it. I'm highly competitive and love challenging myself to become the best at something,
                    and I think my obsessive focus comes from that. I see that focus as a strength, as it helps me achieve ambitious goals.
                    For example, when I started kickboxing, it had to be five times a week, and it was important for me to work towards an actual fight in the ring.
                    Martial arts quickly became a new passion. For the gamers: Dark Souls is my favorite series. I enjoy being challenged,
                    because overcoming challenges while being uncomfortable is where I believe I grow the most as a person.
                  </p>
                </div>

              )
          }
        }
      },
      contact: {
        title: "Get in Touch",
        description: (<>Got an exciting idea for a collaboration or questions about the projects I work on? <br />
          Or maybe you just want to chat about the latest tech trends? <br />
          Hit the button below and let's start a conversation!</>),
        button: "Contact Me"
      }
    },
    articlePage: {
      articles: {
        category: {
          "science": "Scientific",
          "medium": "Medium",
        },
        alLabelling: {
          title: "Active learning for reducing labeling effort in text classification tasks",
          excerpt: `Labelling data manually is very time consuming and costly.
      In this study, we explore how to reduce this labelling effort through use of active learning.
      We compare different uncertainty-based algorithms to select a subset of data on which, in hopes of achieving
      similar performance to using the full dataset, to train BERTbase on in a text classification setting.
      Moreover we propose and examine heuristics to address scalability and outlier selection issues linked to pool-based active learning. 
      While these heuristics didn’t improve AL, 
      our findings show that uncertainty-based AL outperforms random sampling, 
      though the performance gap narrows as query-pool sizes increase.`,
        },
        LLMDSL: {
          title: "Developing Large Language Models for Quantum Chemistry Simulation Input Generation",
          excerpt: `Scientists often struggle with Domain-Specific Languages (DSLs) in fields like computational chemistry. 
      In this paper, we propose a general framework for using Large Language Models (LLMs) to generate DSL code, using
      the ORCA quantum chemistry package as a case study. 
      This framework includes various prompt engineering techniques, finetuning with different synthetic datasets, and use of retrieval augmented generation. 
      Our framework significantly boosted LLM performance, even surpassing the GPT-4o model when adopting the less powerful GPT 3.5 model as base.`,
        },
      },
      searchPlaceholder: "Search Articles...",
      sort: {
        date: "Sort by Date",
        readingTime: "Sort by Reading Time",
      },
      timeRead: "min read"
    },
    experiencePage: {
      title: "Explore my Career",
      description: `Below is a timeline highlighting the key milestones in my career that I believe have been instrumental in shaping who I am today.
      Feel free to explore and expand the different milestones to read more about them. My hope is, of course, that I can continue to keep expanding this list frequently.`,
      types: {
        work: 'Work',
        education: 'Education',
        other: 'Other',
        all: "All"
      },
      expand: "Expand All",
      collapse: "Collapse All",
      experiences: {
        bachelor: {
          
          date: "September 2018 - July 2021",
          where: 'University of Groningen',
          description: (
            <>
              Graduated with an average of 7.7, my course list can be found <CustomLink to="documents/bachelor_courses.pdf" download="bacherlor_courses.pdf">here</CustomLink>.
              I went on to publish an adjusted version of my bachelor thesis in the BNAIC 2021 conference proceedings, the final article can be read <CustomLink to="https://arxiv.org/abs/2109.04847" external={true}>here</CustomLink>.
            </>
          ),
        },
        master: {
          date: "September 2021 - July 2024",
          where: 'University of Groningen',
          description: (
            <>
              Graduated cum laude with an average of 8.6, taking the Computational Intelligence track. This specialization has a focus on machine learning, my full course list can be found <CustomLink to="documents/master_courses.pdf" download="master_courses.pdf">here</CustomLink>.
              Moreover, I published an adjusted version of my master thesis in the <CustomLink to="https://pubs.rsc.org/en/journals/journalissues/dd#!recentarticles&adv" external={true}>Digital Discovery journal</CustomLink> (impact factor: 6.2).
              The article can be found <CustomLink to="https://chemrxiv.org/engage/chemrxiv/article-details/66d20058a4e53c487628f63b" external={true}>here</CustomLink>.
            </>
          )
        },
        yag: {
          date: "May 2022 - February 2024",
          title: 'Strategy Consultant',
          description: (
          <div className='flex flex-col gap-y-2'>
            <p>Coached by top consulting firms, like Berenschot, McKinsey, Roland Berger and BCG, I provided strategic advice to small and medium
        enterprises, multinationals, and government institutions. Example projects I worked on include a life cycle analysis of paper and digital books as well as advising about the use of AI in the Dutch Educational system.
            </p>
            <p>
        Among other things, I gained experience in how to brainstorm and initiate new ideas in a larger organization, in presenting and pitching, in how to lead a team and in proper project management`,
        </p>
        </div>)
        },
        omun: {
          title: "Fullstack Website Developer (Founder & Director)",
          date: "August 2020 - January 2024",
          description: 
          <div className='flex flex-col gap-y-2'>
            <p>
            Designed, built and deployed custom web applications for clients, mainly through use of the MERN stack. One project example involved developing a platform where a major Malaysian fish seller could record all its purchases and sales.
            </p>
            <p>
            I learned how to tackle the challenges that come with implementing software and AI for real user products, where data is often limited and where there is more to deliver than just good software to get to a result that a client is happy with.
            </p>
            </div>
        },
        kickbox: {
          title: "Kickboxing Fight",
          description: `Trained six times a week in preparation for a kickboxing event where I entered the ring for a match which I ended up winning. I was trained by a former world champion and the training camp spanned a total of three months.
          
          This was a challenging period, where i gained a lot of discipline, confidence and had to learn how to perservere even when things looked grim every now and then.`,
        },
        umcg: {
          date: "March 2022 - March 2023",
          description: (
          <div className='flex flex-col gap-y-2'>
            <p>
            Worked on a computer vision project concerning the detection of Aspergillus in the lungs. We used machine learning to discriminate
            proven fungal infections from control.
            </p>
            <p>
            My main takeaway from this experience was that I enjoyed working in a multidisciplinary environment. I got experience in understanding the expert knowledge of others to a degree where I could translate it into the product I was building for them, and of course in explaining my expert knowledge to others in a simple to understand manner.`,
            </p>
          </div>
          ),
          where: 'University Medical Centre Groningen',
        },
        bg: {
          date: "September 2020 - April 2024",
          title: "Captain and Tour Guide",
          description: (
          <div className='flex flex-col gap-y-2'>
            <p>
              Toured people around the canals of Groningen by boat in which I served as the captain. This included doing historical tours where I told about the history and notable parts of the city.
            </p>
            <p>
            I gained a lot of experience in presenting to different types of people, learned how to get to have fun conversations with all types of personalities and learned to get and keep authority over a group of people you don't know.
            </p>
          </div>
          ),
        },
        doordeweeks: {
          description: (
            <>
              Ran a monthly humouristic podcast with comedian and good friend <CustomLink to="https://maxvanschaik.nl/">Max van Schaik</CustomLink>. This was a fun project where we went out of our comefort zone
              to do all sorts of ridiculous challenges. The episodes can be found on <CustomLink to="https://open.spotify.com/show/6QuNwZVcODW9uakJzpOLs7?si=5c36bacf54af4e77" external={true}>Spotify</CustomLink>. It taught me about the recording and editing of speech, and about structuring the content for online media.
            </>
          ),
        },
        minor: {
          title: 'Minor Entrepreneurship',
          where: 'University of Groningen',
          description: `Aside from taking courses on scientific and proven approaches to entrepreneurship as well as the legal and financial parts of it, 
      I worked in a multidisciplinary project group to develop an empirically grounded solution for a practical business development challenge. 
      Me and my group members developed a product to monitor what privacy policies of used products conflict with the user's privacy preferences. We won
      the award for best product pitch.`,
        },
        dialogic: {
          date: "February 2021 - July 2021",
          title: "Machine Learning Engineer (Intern)",
          description: (
            <div className='flex flex-col gap-y-2'>
              <p>
              Created an application to reduce labeling effort of text classification tasks through use of BERT language model with active learning. The application had to be usable and understandable for employees with no knowledge of machine learning.
              </p>
              <p>
              I gained a lot of understanding on how to build a product for people that don't have much technical experience and how to integrate a new product into a companies day-to-day operations.
              </p>
          </div>
          ),
        },

        onethird: {
          date: "November 2024 - Present",
          where: 'OneThird',
          description: (
            <div className='flex flex-col gap-y-2'>
            <p>
            I'm developing scalable, data-driven solutions that assist growers, distributors, and retailers in minimizing their waste by predicting the shelf-life of fresh produce.
            With these predictions:
            </p>
              <ul>
                <li>🌱 Growers can strategically decide where to ship different batches to ensure customers receive top-quality products.</li>
                <li>🚚 Distributors can optimize their routing decisions based on freshness criteria for reliable deliveries.</li>
                <li>🏪 Retailers can empower their staff and clients to swiftly evaluate in-store product freshness.</li>
              </ul>
            </div>
          ),
        },

      }
    }
  },
  nl: {
    navbar: {
      about: "Over Pieter",
      articles: "Mijn Artikelen",
      experience: "Mijn Ervaring",
      languageName: {
        en: "Engels",
        nl: "Nederlands"
      },
    },
    homepage: {
      frontpage: {
        title: "Hoi, ik ben Pieter",
        intro: (<>Op dit moment zet ik mijn kennis die ik in mijn Bsc. en Msc. Kunstmatige Intelligentie heb opgedaan in bij <CustomLink to='https://onethird.io/?__hstc=177026938.db2eff16bd6670e61ed4c56d387ea301.1723543355164.1724930932484.1726171358501.4&__hssc=177026938.1.1726171358501&__hsfp=1350194568'>OneThird</CustomLink> om voedselverspilling te verminderen.
          Het grootste deel van mijn tijd gaat op aan deze missie, maar omdat ik niet goed stil kan zitten, blijf ik altijd bezig met hiernaast allerlei projectjes op te pakken.
          Op deze website vind je een overzicht van alle coole dingen die ik doe, en in het verleden heb gedaan.</>),
        explore: "Bekijk mijn Werk"
      },
      about: {
        title: "Over Mij",
        summary: "Ik ben een creatief persoon die graag het leven van anderen een stukje beter maakt. Ik doe dit het liefst door te werken aan grootschalige problemen, en ik gebruik hiervoor mijn expertise in Kunstmatige Intelligentie gecombineerd met mijn ondernemende houding om mijn vele ideeën te realiseren. Als een probleem of thema eenmaal met mij resoneert, kan ik het niet meer loslaten en doe ik er alles aan om hier alles aan om het op te lossen of om er alles over te weten te komen.",
        learnMore: (<>Als je meer over mij wilt leren, kun je op de onderstaande kopjes drukken of door <CustomLink to="/experience">mijn professionele ervaring</CustomLink> heen gaan.</>),
        items: {
          ai: {
            "title": "Mijn Passie voor Kunstmatige Intelligentie",
            "content": (
              <div className='flex flex-col gap-y-2'>
                <p>Vanaf jongs af aan ben ik altijd een spelletjesmens geweest. Ik schaak(te) erg veel en heb ook door de tijd heen
                  verschillende trading card games gespeeld. Ik vond het leuk om bij dit soort spellen beter te worden in het maken van de juiste keuzes en om te leren over alle mogelijke strategieën.
                  Mijn liefde voor spelletjes leidde ertoe dat ik in aanraking kwam met Kunstmatige Intelligentie, wat werd gebruikt om computers deze spellen op hoog niveau te kunnen laten spelen.
                  Ik vond het facinerend om te zien hoe een computer deze spellen kon leren beheersen en mensen zelfs konden verslaan. Het riep ook, logischerwijs, veel vragen bij mij op:
                </p>
                <ul className='ml-4 list-disc list-inside'>
                  <li>Hoe werken deze algoritmes?</li>
                  <li>Wat voor andere complexe taken zou KI kunnen doen? </li>
                  <li>Zijn de modellen echt intelligent, en kan menselijke intelligentie worden nagebootst?</li>
                </ul>
                <p>
                  Op zoek naar antwoorden begon ik Kunstmatige Intelligentie te studeren aan de <CustomLink to="https://www.rug.nl/" external>Rijksuniversiteit Groningen</CustomLink>.
                </p>
                <p>
                  Gelukkig kreeg ik deze tijdens mijn Bsc. en Msc. KI, al blijft de kwestie over menselijke intelligentie nog altijd mooi voer voor discussies. Ik leerde over de basisprincipes van KI, en uit welke bouwstenen
                  alles is opgebouwd. De verschillende velden binnen KI kwamen natuurlijk allemaal aan bod, van robotics tot multi-agent systems tot de inmiddels welbekende machine en deep learning (want ja, deze zijn niet hetzelfde als KI maar slechts een onderdeel ervan).
                  Heel veel informatie waarvan ik het meeste razend interessant vond, vooral wanneer het concreet toepasbaar was. Veel van de moderne modellen en algoritmes stonden toendertijd nog in de kinderschoenen en het is nu leuk om terug te kijken
                  op hoe mensen geen idee hadden van wat mijn studie inhield.
                </p>
                <p>
                  Dit is natuurlijk tegenwoordig wel anders. AI is inmiddels gewoon een buzzword, en allerlei snelle jongens en dames springen maar al te graag op de hype.
                  Dat KI voor de meeste nu eigenlijk gelijk te staan aan Large Language Models (LLMs), doet niet af van dat
                  iedereen inmiddels bekend is met de term. Daarnaast is het ook een stuk laagdrempeliger geworden om met en aan KI te werken.
                  Elke beginnende programmeur kan
                  in enkele regels code een neuraal netwerk of een ander ingewikkeld model opzetten en deze gebruiken om redelijke resultaten te behalen.
                  Dit zal alleen maar makkelijker worden, zeker met de hulp van die eerder genoemde LLMs.
                  </p> 
                  <b>Dus wat is mijn toegevoegde waarde dan nog?</b>
                  <p>
                  Ik denk dat dit neer komt op de kennis van de daadwerkelijke werking van wat er achter de schermen gebeurd, en van wat de achtergronden en doelen
                  van de verschillende delen van wat we KI noemen zijn. Om KI goed te kunnen inzetten en om te kunnen innoveren binnen het veld,
                  geloof ik dat men moet beginnen bij de eerste principes. Dit omvat ook kennis over hoe de hersenen werken
                  en welke delen van de psychologie en filosofie relevant zijn voor het simuleren van menselijke intelligentie. Deze kennis is nodig bij verschillende grote vragen
                  die in de nabije toekomst beantwoord moeten worden.
                  </p>
                  <p>
                  Om er maar een paar te noemen:
                  </p>
                  <ul className='ml-4 list-disc list-inside'>
                    <li>Welke rol speelt bewustzijn in KI, of god verhoede, wat is bewustzijn eigenlijk?</li>
                    <li>Hoe komen we tot biologisch plausibele KI?</li>
                    <li>Hoe creëren en leren mensen taal?</li>
                    <li>Hoe kunnen we veilige KI maken?</li>
                  </ul>
                <p>
                Mijn doel is om regelmatig over dit soort vragen te schrijven en mijn gedachten te delen via online content. Mede omdat ik het leuk vind om
                mijn kennis te delen maar ook omdat dit mij zelf scherp houdt!
                </p>
              </div>
            ),
          },
          "focus": {
            "title": "Mijn Focus op Impact",
            "content": (
              <div className='flex flex-col gap-y-2'>
                <p>
                  De projecten die mij trekken zijn over het algemeen vrij ambitieus. Er moet een grote uitdaging liggen
                  om mijn interesse te trekken, eentje die de mogelijkheid heeft om de wereld op een positieve manier te veranderen.
                  Eentje waarover ik twijfel of het eigenlijk wel op te lossen valt. Dan kan ik namelijk
                  aan mezelf bewijzen dat dit wel degelijk mogelijk is. Verder, als ik even goed in de spiegel kijk, denk ik dat ik graag bevestigd
                  wordt in het idee dat mijn bestaan niet voor niets is, en dat kleine groepjes individuen wel degelijk een verschil kunnen maken.
                  Als ik rationeel nadenk kom ik altijd tot de conclusie dat mensen vrij nietig zijn, maar hier probeer ik optimistich over te blijven,
                  want wat heeft die negativiteit voor zin?
                </p>
                <p>
                  Er zijn namelijk veel voorbeelden van groepen mensen die van niets naar iets zijn gegaan. Ik denk dat ik wel het een en ander in mijn mars heb,
                  dus waarom zou ik dat dan niet ook kunnen. Het zou zonde zijn als ik mijn tijd en talent zou verspillen aan minder, nu ik nog de energie en mindset heb om achter dit soort dingen aan
                  te gaan. Als ik dan ook zo'n project vind, wat ook met mij resoneert, dan verlies ik mijzelf daar graag in. Neem bijvoorbeeld
                  mijn project over het inzetten van KI om schimmel infecties in de longen eerder te kunnen herkennen of mijn huidige functie waar ik
                  KI inzet om voedselverspilling tegen te kunnen gaan.
                </p>
              </div>
            ),
          },
          "entrepreneurial": {
            "title": "Mijn Ondernemende Instelling",
            "content":
              (
                <div className='flex flex-col gap-y-2'>
                  <p>
                    Ik geloof dat je als kind in zekere zin nog puur bent. Kinderen zijn helemaal zichzelf en daarom valt er geloof ik al veel te zeggen over het karakter en de persoonlijkheid
                    van wie ze als mens zijn en gaan worden. Nee, ik wil hier geen nature versur nurture debate neer gaan kalken, maar even
                    mijn jongere zelf erbij halen als voorbeeld van mijn ondernemende mindset. Van jongs af aan was ik namelijk al druk met nieuwe
                    dingen bedenken, maken, en het verkopen hiervan vond ik ook erg belangrijk. 
                  </p>
                  <p>
                    Een mooi voorbeeld gaat terug naar de basisschool, waar ik spellen maakte waar de hele school aan mee kon doen.
                    Ik ontwierp uitgebreide kaarten van fantasiewerelden waar leerlingen hun eigen land konden kopen. Dit land konden ze inrichten met
                    allerlei producten die ik aanbood en voor hun heel mooi op hun stukje van de kaart erbij tekende.
                    <i>Maar voor niets gaat de zon op!</i> Ik besloot we dat we een munteenheid nodig hadden zodat men mij kon betalen, en met elkaar kon handelen; oud papier werd het geïmproviseerde geld.
                    Al snel bloeide de "economie", met kinderen die handelden, bouwden en—onvermijdelijk—oorlogen tegen elkaar voerden.
                    Oorlog is natuurlijk duur, dus ik werd al snel een behoorlijk rijk individu, wat betekende dat mijn moeder zich nooit meer zorgen hoefde te maken dat het papier voor boodschappenlijstjes op zou raken.
                  </p>
                  <p>
                    Dit soort succes, naast mijn kneedgom handel en de verkoop van uit balpen gemaakte mesjes, motiveerde mij om mijn ideeën uit te blijven werken.
                    In plaats van een regulier PWS, besloot ik om een Black Mirror-esque boek te schrijven over een monopolist die handelde in data (iets wat, achteraf gezien, een behoorlijk nauwkeurige voorspelling van de wereld van vandaag bleek te zijn).
                    Deze bracht ik met mijn co-auteur uit en verkochten we aan iedereen die nieuwsgierig was.
                    Ik veranderde mijn passie voor sneakers ook in een kleine handel en probeerde zelfs—hoewel zonder succes—een MMORPG te maken om met mijn vrienden te spelen.
                    Het zien van mensen die genieten van of profijt hebben van de dingen die ik maak, is altijd enorm bevredigend geweest, en die drang is me door de jaren heen bijgebleven.
                  </p>
                  <p>
                    Toen ik begon aan mijn studentenleven, was het wel een keer tijd om voor het echie te beginnen: ik richtte met een studiemaat een B.V. op genaamd Omun. Gezien mijn programmeerervaring toen nog vrij summier was begonnen we
                    met simpel web development voor MKBers. Al snel begonnen we dit echter om te gooien naar het maken van web apps met AI integraties. Dit maakte dat wij de theorie die we leerde direct
                    op echte casussen konden toepassen; enorm leerzaam natuurlijk. Tijdens mijn minor in ondernemerschap gold hetzelfde: ik kon de inzichten gebruiken om onze bedrijfsvoering
                    te verbeteren. Mijn interesse in ondernemersschap resulteerde ook in mijn positie bij de door studenten gerunde consultancy genaamd <CustomLink to='https://youngadvisorygroup.nl/'>Young Advisory Group</CustomLink>.
                    Hier deed ik waardevolle ervaring op in het managen van een groterer organisatie en genoot ik van de vrijheid in het pitchen van ideeën. YAG's bedrijfsstructuur is plat,
                    wat betekende dat je praktisch alles kon initieren, zolang je maar genoeg support kreeg van je collega's. Zo heb ik de digitale structuur van het bedrijf
                    geherstructureerd, een carrière-evenement georganiseerd een incubator opgezet en nog veel meer.
                  </p>
                  <p>
                    In de toekomst wil ik altijd bij organisaties werken waar ondernemerschap een kernwaarde is, mede door mijn tijd bij YAG en Omun.
                    Om nog meer te leren over deze ervaringen kan je <CustomLink to="/experinece">hier</CustomLink> klikken.
                  </p>
                </div>
              ),
          },
          "creative": {
            "title": "Mijn Creativiteit en Focus",
            "content":
              (
                <div className='flex flex-col gap-y-2'>
                  <p>
                    Iets werkelijkheid maken wat in mijn gedachten is opgekomen vind ik misschien wel het allerleukste wat er is. Het meeste wat je duidelijk kan visualiseren,
                    kan je denk ik ook werkelijkheid maken. Misschien dat ik hierdoor ook wel begon met programmeren, en dan specifiek met web development. Dit was een perfecte manier
                    om bij elke lijn code direct resultaat te zien, tenzij het om het centreren van een <i>div</i> gaat natuurlijk. Daarnaast gaven web applicaties mij de mogelijkheid om direct dingen te maken die
                    toegankelijk waren voor ieder met een internetverbinding.
                  </p>
                  <p>
                    Na te beginnen in web development, kwam ik tot de conclusie dat technologie een goede manier was om mijn creativiteit te uitten. Ik verloor mezelf in verschillende onderwerpen,
                    zoals kwantum, AI maar ook bijvoorbeeld de omstreden wereld van crypto. Zo stak ik ik enorm veel in het bouwen van Solidity decentralized apps.
                    Ondanks dat decentralisatie eigenlijk heel meestal niet nodig bleek te zijn, vond ik het proces van zo'n nieuwe technologie leren erg tof.
                    Natuurlijk hoeft ook niet alles een goede use case te hebben, en vind ik het ook belangrijk dat dingen maken soms simpelweg voor de lol blijft.
                    Zo maak ik vanaf jongs af aan muziek, schrijf ik graag korte fictie verhalen, ben ik ooit een humoristische podcast begonnen met een goede vriend,
                    en maak ik graag customized spellen om met mijn maten te spelen. Van dit soort dingen krijg ik energie. Ik ben niet bang om
                    mezelf bloot te geven voor publiek en vind het heel leuk om bijvoorbeeld online content te maken. Mijn doel is dan ook om
                    dit in de nabije toekomst te blijven doen.
                  </p>
                  <p>
                    Als ik zoiets vind wat met mij resoneert verlies ik mij er graag in. Ik ben erg competitief en daag mezelf graag uit om ergens de beste in te worden, wat resulteerd in dat ik een extreme focus op één onderwerp heb.
                    Die focus zie ik als een kracht, aangezien het me helpt om ambitieuze doelen te behalen. Toen ik bijvoorbeeld begon met kickboksen,
                    moest dit dus ook vijf keer per week zijn en vond ik het belangrijk om naar een gevecht in de ring te werken. Martial Arts werd al snel een nieuwe passie.
                    Voor de gamers: de Dark Souls series zijn mijn soort spellen. Ik wordt graag uitgedaagd en gooi mezelf regelmatig in situaties waar ik me niet op mijn gemak voel, want dit is waar ik denk ik als persoon
                    het meest van groei.
                  </p>
                  <p>
                  </p>
                </div>
              )
          }
        }
      },
      contact: {
        title: "Neem Contact Op",
        description: (<>Wil je met mij samenwerken of heb je vragen over de projecten waar ik aan werk? <br />
          Of wil je gewoon met mij sparren over ontwikkelingen binnen tech, of over iets anders? <br />
          Klik op de knop beneden om met mij in contact te komen!</>),
        button: "Email Mij"
      },
    },
    articlePage: {
      articles: {
        category: {
          "science": "Wetenschappelijk",
          "medium": "Medium",
        },
        alLabelling: {
          title: "Active Learning om labellingsinspanning bij tekstclassificatietaken te verminderen",
          excerpt: `Het handmatig labelen van data is tijdrovend en kostbaar.
          In deze studie onderzoeken we hoe deze labellingsinspanning kan worden verminderd door gebruik te maken van Active Learning (AL).
          We vergelijken verschillende onzekerheidsgebaseerde algoritmen om een subset van de gebruikte dataset te selecteren waarop, in de hoop vergelijkbare prestaties te bereiken als bij het gebruik van de volledige dataset, BERTbase kan worden getraind in tekstclassificatie.
          Bovendien stellen we verschillende heuristieken voor en onderzoeken we deze om schaalbaarheids- en uitschieterselectieproblemen aan te pakken die verband houden met pool-gebaseerd AL.
          Hoewel deze heuristieken AL niet verbeterden,
          tonen onze bevindingen aan dat onzekerheidsgebaseerd AL beter presteert dan willekeurige sampling,
          hoewel het prestatieverschil kleiner wordt naarmate de grootte van de query-pool toeneemt.`,
        },
        LLMDSL: {
          title: "Ontwikkeling van Large Language Models voor het genereren van code voor simulaties in kwantumchemie",
          excerpt: `Wetenschappers worstelen vaak met domeinspecifieke programmeertalen (DSL's) in vakgebieden zoals computationele chemie.
          In dit artikel stellen we een algemeen framework voor om Large Language Models (LLM's) te gebruiken voor het genereren van DSL-code, waarbij het ORCA-kwantumchemiepakket als casestudy wordt gebruikt.
          Dit framework omvat verschillende technieken voor prompt-engineering, finetunining op verschillende synthetisch gecreeerde datasets, en gebruik van retrieval augmented generation.
          Ons framework verhoogde de prestaties van LLM's aanzienlijk en overtrof zelfs het GPT-4o-model bij gebruik van het minder krachtige GPT 3.5-model als basis.`,
        },
      },
      searchPlaceholder: "Zoek Artikelen...",
      sort: {
        date: "Sorteren op Datum",
        readingTime: "Sorteren op Leestijd",
      },
      timeRead: "min leestijd"
    },
    experiencePage: {
      title: "Verken mijn Carrière",
      description: `Hieronder staat een tijdlijn met de belangrijkste mijlpalen in mijn carrière. Deze heben een belangrijke rol hebben gespeeld in mijn ontwikkeling.
      Om meer te weten te komen over de mijlpalen kan je er op klikken om er meer over te lezen. Mijn hoop is natuurlijk dat ik deze lijst nog vaak kan blijven uitbreiden.`,
      types: {
        work: 'Werk',
        education: 'Onderwijs',
        other: 'Overig',
        all: 'Alles',
      },
      expand: "Klap Mijlpalen uit",
      collapse: "Klap Mijlpalen in",
      experiences: {
        bachelor: {
          date: "September 2018 - Juli 2021",
          where: 'Rijksuniversiteit Groningen',
          description: (
            <>
              Afgestudeerd met een gemiddelde van 7,7. Mijn vakken met bijbehorend cijfer zijn <CustomLink to="documents/bachelor_courses.pdf" download="bacherlor_courses.pdf">hier</CustomLink> te vinden.
              Ik heb een aangepaste versie van mijn bachelorthesis gepubliceerd in de BNAIC 2021 conferentie-proceedings, het artikel is <CustomLink to="https://arxiv.org/abs/2109.04847" external={true}>hier</CustomLink> te lezen. Deze heb ik ook op de conferentie mogen presenteren aan vooraanstaande AI-onderzoekers.
            </>
          ),
        },
        master: {
          date: "September 2021 - Juli 2024",
          where: 'Rijksuniversiteit Groningen',
          description: (
            <>
              Cum laude afgestudeerd met een gemiddelde van 8,6 in de Computational Intelligence track. Deze specialisatie richt zich op machine learning; mijn volledige vakkenlijst is <CustomLink to="documents/master_courses.pdf" download="master_courses.pdf">hier</CustomLink> te vinden.
              Verder heb ik een aangepaste versie van mijn master thesis gepubliceerd in het <CustomLink to="https://pubs.rsc.org/en/journals/journalissues/dd#!recentarticles&adv" external={true}>Digital Discovery journal</CustomLink> (impact factor: 6.2).
              Het artikel is <CustomLink to="https://chemrxiv.org/engage/chemrxiv/article-details/66d20058a4e53c487628f63b" external={true}>hier</CustomLink> te vinden.
            </>
          )
        },
        yag: {
          date: "Mei 2022 - Februari 2024",
          title: 'Strategisch Adviseur',
          description: 
          <div className='flex flex-col gap-y-2'>
          <p>
            Gecoacht door topconsultancybureaus zoals Berenschot, McKinsey, Roland Berger en BCG, heb ik strategisch advies gegeven aan kleine en middelgrote bedrijven, multinationals en overheidsinstanties.
            Voorbeelden van projecten zijn onder andere een levenscyclusanalyse van papieren en digitale boeken en een adviesproject over het gebruik van AI in het Nederlandse onderwijssysteem.
          </p>
          <p>
            Hierbij heb ik veel ervaring opgedaan met het bedenken en initiëren van nieuwe ideeën binnen een grotere organisatie, presenteren en pitchen, leidinggeven aan een team en projectmanagement.
          </p>
          </div>
        },
        omun: {
          title: "Fullstack Website Ontwikkelaar (Oprichter & Directeur)",
          date: "Augustus 2020 - Januari 2024",
          description: (
          <div className='flex flex-col gap-y-2'>
            <p>
              Op maat gemaakte web applicaties ontworpen, gebouwd en geïmplementeerd voor klanten, voornamelijk met gebruik van de MERN stack. Een projectvoorbeeld omvatte het ontwikkelen van een platform waarop een grote Maleisische visverkoper al zijn aankopen en verkopen kon registreren.
            </p>
            <p>
              Ik heb geleerd om om te gaan met de uitdagingen die komen kijken bij het implementeren van software en AI voor producten met echte gebruikers, waarbij data vaak beperkt is en er meer nodig is dan alleen goede software om tot een resultaat te komen waarmee de klant tevreden is.
            </p>
          </div>
          ),
            },
        kickbox: {
          title: "Kickbokswedstrijd",
          description: `Zes keer per week getraind ter voorbereiding op een kickboksevenement waarin ik de ring betrad voor een wedstrijd die ik uiteindelijk won. Ik werd getraind door een voormalig wereldkampioen en het trainingskamp duurde in totaal drie maanden.
              
              Dit was een uitdagende periode waarin ik veel discipline en zelfvertrouwen heb ontwikkeld en heb geleerd om door te zetten, ook wanneer het soms moeilijk was.`,
        },
        umcg: {
          date: "Maart 2022 - Maart 2023",
          description: (
          <div className='flex flex-col gap-y-2'>
            <p>
            Gewerkt aan een computer vision project met betrekking tot de detectie van Aspergillus in de longen. We gebruikten machine learning om bewezen schimmelinfecties te onderscheiden van de controlegroep.
            </p>
            <p>
            Mijn belangrijkste leerpunt was dat ik het leuk vond om in een multidisciplinaire omgeving te werken. Ik heb ervaring opgedaan met het begrijpen van de expertise van anderen en deze te vertalen naar het product dat ik voor hen bouwde, en natuurlijk in het eenvoudig uitleggen van mijn eigen expertise aan anderen.
            </p>
          </div>
          ),
          where: 'Universitair Medisch Centrum Groningen',
        },
        bg: {
          date: "September 2020 - April 2024",
          title: "Kapitein en Gids",
          description: (
            <div className='flex flex-col gap-y-2'>
              <p>
              Tours gegeven rond de grachten van Groningen als kapitein van de boot. Dit omvatte ook het geven van historische tours waarbij ik vertelde over de geschiedenis en opmerkelijke delen van de stad.
              </p>
              <p>
              Ik heb veel ervaring opgedaan in het presenteren aan verschillende soorten mensen, geleerd om leuke gesprekken te voeren met allerlei persoonlijkheden en om gezag te hebben en te behouden over een groep mensen die je niet kent.
              </p>
            </div>
          ),
        },
        doordeweeks: {
          description: (
            <>
              Een maandelijkse humoristische podcast gerund met cabaretier en goede vriend <CustomLink to="https://maxvanschaik.nl/">Max van Schaik</CustomLink>. Dit was een leuk project waarin we uit onze comfortzone gingen om allerlei belachelijke uitdagingen aan te gaan. De afleveringen zijn te vinden op <CustomLink to="https://open.spotify.com/show/6QuNwZVcODW9uakJzpOLs7?si=5c36bacf54af4e77" external={true}>Spotify</CustomLink>. Het leerde mij veel over het opnemen en editen van geluid, en het schrijven en structureren van goede online content.
            </>
          ),
        },
        minor: {
          title: 'Minor Ondernemerschap',
          where: 'Rijksuniversiteit Groningen',
          description: `Naast het volgen van cursussen over wetenschappelijke en bewezen benaderingen van ondernemerschap en de juridische en financiële aspecten daarvan, werkte ik in een multidisciplinair projectteam om een empirisch onderbouwde oplossing te ontwikkelen voor een praktische uitdaging op het gebied van bedrijfsontwikkeling.
          Samen met mijn groepsleden ontwikkelden we een product om te controleren welke privacybeleid van gebruikte producten conflicteren met de privacyvoorkeuren van de gebruiker. We wonnen de prijs voor beste productpitch.`,
        },
        dialogic: {
          date: "Februari 2021 - Juli 2021",
          title: "Machine Learning Engineer (Stagiair)",
          description: `Een applicatie gecreëerd om de labelinspanning van tekstclassificatietaken te verminderen met behulp van het BERT-taalmodel en actieve leermethoden. De applicatie moest bruikbaar en begrijpelijk zijn voor medewerkers zonder kennis van machine learning.
              
              Ik heb veel geleerd over hoe je een product bouwt voor mensen zonder veel technische ervaring en hoe je een nieuw product integreert in de dagelijkse werkzaamheden van een bedrijf.`,
        },
        onethird: {
          date: "November 2024 - Heden",
          where: 'OneThird',
          description: (
            <div className='flex flex-col gap-y-2'>
              <p>
              Ik ontwikkel schaalbare, data-gedreven oplossingen die telers, distributeurs en retailers helpen hun afval te minimaliseren door de houdbaarheid van verse producten te voorspellen.
              Met deze voorspellingen:
              </p>
              <ul>
                <li>🌱 Kunnen telers strategisch beslissen waar welk product het beste heen kan, zodat klanten producten van topkwaliteit ontvangen.</li>
                <li>🚚 Kunnen distributeurs hun routekeuzes optimaliseren op basis van versheidscriteria voor betrouwbare leveringen.</li>
                <li>🏪 Kunnen retailers hun personeel en klanten in staat stellen om snel de versheid van producten in de winkel te beoordelen.</li>
              </ul>
            </div>
          ),
        },
      }
    },
    footer: {
      copyright: "Pieter Floris Jacobs. Alle rechten voorbehouden.",
      location: "Amsterdam, Nederland"
    },
  }
};

export type Language = 'en' | 'nl';