import React, { useState, useEffect } from 'react';
import { ChevronDown } from 'lucide-react';
import AboutMe from '../sections/AboutMe';
import ContactMe from '../sections/ContactMe';
import { Link } from 'react-router-dom';
import { useLanguage } from '../LanguageContext';

const HomePage = () => {
  const [scrolled, setScrolled] = useState(false);
  const { t } = useLanguage(); // Use the custom hook
  
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="min-h-screen py-20 md:py-16 lg:py-0 bg-gray-50 dark:bg-gray-900 transition-colors duration-300">
      <div className="container mx-auto px-4 py-[2rem] md:py-[4rem] lg:py-[0rem] lg:flex lg:items-center lg:h-screen">
        <div className="flex flex-col md:flex-row items-center bg-white dark:bg-gray-800 rounded-lg shadow-xl overflow-hidden transition-colors duration-300">
          <div className="md:w-1/2">
            <img 
              src="./images/laughing.jpg" 
              alt="Pieter Jacobs Presenting" 
              className="w-full h-64 md:h-full object-cover"
            />
          </div>
          <div className="md:w-1/2 p-6 md:p-12">
            <h1 className="text-2xl md:text-4xl lg:text-6xl font-bold text-gray-800 dark:text-white mb-4 transition-colors duration-300">
              {t('homepage.frontpage.title')}
            </h1>
            <p className="text-lg md:text-xl text-gray-600 dark:text-gray-300 mb-8 transition-colors duration-300">
              {t('homepage.frontpage.intro')}
            </p>
            <Link key="explore_work" to="/experience"
                  className="bg-blue-500 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-2xl transition duration-300"
                >
                   {t('homepage.frontpage.explore')}
                </Link>
          </div>
        </div>
      </div>
      <div className={`fixed bottom-8 left-1/2 transform -translate-x-1/2 transition-opacity duration-300 ${scrolled ? 'opacity-0' : 'opacity-100'}`}>
        <ChevronDown size={32} className="hidden lg:block text-gray-600 dark:text-gray-400 animate-bounce" />
      </div>
      <AboutMe/>
      <ContactMe/>
    </div>
  );
};

export default HomePage;