import React from 'react';
import { Mail, Phone, MapPin } from 'lucide-react';
import { useLanguage } from '../LanguageContext';

const Footer: React.FC  = () => {
  const { t } = useLanguage(); // Use the custom hook

  return (
    <footer className="bg-gray-100 dark:bg-gray-800 py-6 transition-colors duration-300">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-sm text-gray-600 dark:text-gray-300 mb-4 md:mb-0">
            <p className='text-center md:text-left'>
            © {new Date().getFullYear()} {t('footer.copyright')}
            </p>
          </div>
          <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-6">
            <div className="flex items-center space-x-2">
              <Mail className="w-4 h-4 text-blue-500" />
              <span className="text-sm text-gray-600 dark:text-gray-300">personal@pfjacobs.com</span>
            </div>
            <div className="flex items-center space-x-2">
              <Phone className="w-4 h-4 text-blue-500" />
              <span className="text-sm text-gray-600 dark:text-gray-300">+31 617974012</span>
            </div>
            <div className="flex items-center space-x-2">
              <MapPin className="w-4 h-4 text-blue-500" />
              <span className="text-sm text-gray-600 dark:text-gray-300">{t('footer.location')}</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;