import React from 'react';
import ExpandableList from '../components/ExpandableList';
import { useLanguage } from '../LanguageContext';

const AboutMe: React.FC = () => {
  const { t } = useLanguage(); // Use the custom hook
  const aboutMeItems = [
    {
      title: t('homepage.about.items.ai.title'),
      content: t('homepage.about.items.ai.content')
    },
    {
      title: t('homepage.about.items.focus.title'),
      content: t('homepage.about.items.focus.content')
    },
    {
      title:  t('homepage.about.items.entrepreneurial.title'),
      content: t('homepage.about.items.entrepreneurial.content')
    },
    {
      title:  t('homepage.about.items.creative.title'),
      content: t('homepage.about.items.creative.content')
    },
  ];

  return (
    <section className="py-16 bg-white dark:bg-gray-800 transition-colors duration-300">
      <div className="container mx-auto px-4">
        <h2 className="mb-4 text-2xl md:text-3xl font-bold text-center text-gray-800 dark:text-white transition-colors duration-300">
          {t("homepage.about.title")}
        </h2>
        <div className="flex flex-col items-center">
          <div className='flex flex-col gap-y-2 w-[80%] md:w-[60%]'>
            <p className="text-lg text-gray-600 dark:text-gray-300 transition-colors duration-300">
              {t("homepage.about.summary")}
            </p>
            <p className="text-lg text-gray-600 dark:text-gray-300 transition-colors duration-300">
              {t("homepage.about.learnMore")}
            </p>

            <ExpandableList items={aboutMeItems} />

          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;