import React, { useState, useEffect, ReactNode  } from 'react';
import {Briefcase, GraduationCap, Award, Download, MapPin} from 'lucide-react';
import CustomLink from '../components/CustomLink';
import { useLanguage } from '../LanguageContext';

interface TimelineEvent {
  id: number;
  date: string;
  title: string;
  where?: string;
  location?: string;
  description: string | ReactNode;
  type: 'work' | 'education' | 'other';
}

const Timeline: React.FC = () => {
  const { t }  = useLanguage();  
  const [expandedEvents, setExpandedEvents] = useState<number[]>([]);
  const [filter, setFilter] = useState<'work' | 'all' | 'education' | 'other'>('all');
  const [isMobile, setIsMobile] = useState(false);
  const [showAllEvents, setShowAllEvents] = useState(false);

  const timelineEvents: TimelineEvent[] = [
    {
      id: 1,
      date: t('experiencePage.experiences.onethird.date'),
      title: "Data Scientist",
      where: t('experiencePage.experiences.onethird.where'),
      description: t('experiencePage.experiences.onethird.description'),
      type: 'work',
    },
    {
      id: 2,
      date: t('experiencePage.experiences.master.date'),
      title: "Msc. Artificial Intelligence",
      where:  t('experiencePage.experiences.master.where'),
      description: t('experiencePage.experiences.master.description'),
      type: 'education',
    },
    {
      id: 3,
      date:  t('experiencePage.experiences.yag.date'),
      title: t('experiencePage.experiences.yag.title'),
      where: 'Young Advisory Group',
      description: t('experiencePage.experiences.yag.description'),
      type: 'work',
    },
    {
      id: 23,
      date:  t('experiencePage.experiences.omun.date'),
      title: t('experiencePage.experiences.omun.title'),
      where: 'Omun B.V.',
      description: t('experiencePage.experiences.omun.description'),
      type: 'work',
    },
    {
      id: 34,
      date: "2023",
      title: t('experiencePage.experiences.kickbox.title'),
      where: 'Groningen',
      description: t('experiencePage.experiences.kickbox.description'),
      type: 'other',
    },

    {
      id: 4,
      date: t('experiencePage.experiences.umcg.date'),
      title: "Machine Learning Engineer",
      where: t('experiencePage.experiences.umcg.where'),
      description: t('experiencePage.experiences.umcg.description'),
      type: 'work',
    },
    {
      id: 5,
      date: "2020-2021",
      title: t('experiencePage.experiences.minor.title'),
      where: t('experiencePage.experiences.minor.where'),
      description: t('experiencePage.experiences.minor.description'),
      type: 'education',
    }, 
    {
      id: 6,
      date: t('experiencePage.experiences.dialogic.date'),
      title: t('experiencePage.experiences.dialogic.title'),
      where: 'Dialogic',
      description: t('experiencePage.experiences.dialogic.description'),
      type: 'work',
    },
    {
      id: 999,
      date: t('experiencePage.experiences.bg.date'),
      title: t('experiencePage.experiences.bg.title'),
      where: 'Boot Groningen',
      description:  t('experiencePage.experiences.bg.description'),
      type: 'work',
    },
    {
      id: 7,
      date: t('experiencePage.experiences.bachelor.date'),
      title: "Bsc. Artificial Intelligence",
      where: t('experiencePage.experiences.bachelor.where'),
      description: t('experiencePage.experiences.bachelor.description'),
      type: 'education',
    },
    {
      id: 77,
      date: "2021",
      title: "Podcast Cohost",
      where: 'Doordeweeks',
      description: t('experiencePage.experiences.doordeweeks.description'),
      type: 'other',
    },
  ];

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    handleResize();
    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleEvent = (id: number) => {
    setExpandedEvents(prev => 
      prev.includes(id) ? prev.filter(eventId => eventId !== id) : [...prev, id]
    );
  };

  const toggleAllEvents = () => {
    setExpandedEvents(expandedEvents.length === timelineEvents.length ? [] : timelineEvents.map(e => e.id));
  };



  const filteredEvents = timelineEvents.filter(event => filter === 'all' || event.type === filter);
  const displayedEvents = showAllEvents ? filteredEvents : filteredEvents.slice(0, 4);

  const getEventIcon = (type: 'work' | 'education' | 'other') => {
    switch (type) {
      case  'work': return <Briefcase className="text-red-500" />;
      case 'education': return <GraduationCap className="text-green-500" />;
      case  'other': return <Award className="text-yellow-500" />;
    }
  };



  return (
    <section className="bg-gray-50 dark:bg-gray-900">   
      <div className="container mx-auto px-4 py-[6rem]">
        <div className="text-center mb-8 md:w-[50%] m-auto">
            <h2 className="text-2xl md:text-3xl font-bold text-gray-800 dark:text-white mb-4">{t('experiencePage.title')}</h2>
            <div className='md:h-[40vh] my-4 bg-white dark:bg-gray-800 rounded-lg overflow-hidden transition-colors duration-300"'>
              <img 
                src="./images/presenting_bachelor.jpg" 
                alt="Pieter Jacobs Presenting" 
                className="w-full md:h-full object-cover"
                loading='lazy'
              />
            </div>
            <p className="text-lg md:text-xl text-gray-600 dark:text-gray-300">
              {t('experiencePage.description')}
            </p>
        </div>
        <div className="flex justify-center space-x-4 mb-8">
          <button onClick={() => setFilter('all')} className={`px-4 py-2 rounded ${filter === 'all' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-800'}`}>{t('experiencePage.types.all')}</button>
          <button onClick={() => setFilter('work')} className={`px-4 py-2 rounded ${filter === 'work' ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-800'}`}>{t('experiencePage.types.work')}</button>
          <button onClick={() => setFilter('education')} className={`px-4 py-2 rounded ${filter === 'education' ? 'bg-green-500 text-white' : 'bg-gray-200 text-gray-800'}`}>{t('experiencePage.types.education')}</button>
          <button onClick={() => setFilter('other')} className={`px-4 py-2 rounded ${filter === 'other' ? 'bg-yellow-500 text-white' : 'bg-gray-200 text-gray-800'}`}>{t('experiencePage.types.other')}</button>
        </div>

        <div className="flex justify-center space-x-4 mb-8">
          <button onClick={toggleAllEvents} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
            {expandedEvents.length === timelineEvents.length ? 'Collapse All' : 'Expand All'}
          </button>
          <CustomLink to="documents/cv.pdf" download="cv_pieter_jacobs.pdf">
            <button className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded flex items-center">
              <Download size={20} className="mr-2" />
              Download CV
            </button>
          </CustomLink>
        </div>

        <div className="relative">
          {/* Vertical line */}
          
          <div className="absolute left-1/2 transform -translate-x-1/2 w-1 h-full bg-blue-500"></div>
          
          {displayedEvents.map((event, index) => (
            <div key={event.id} className={`mb-8 flex justify-between items-center w-full ${isMobile ? 'flex-col' : index % 2 === 0 ? 'flex-row-reverse' : ''}`}>
              <div className={`${isMobile ? 'w-full' : 'w-5/12'}`}>
                <div onClick={() => toggleEvent(event.id)} className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-md cursor-pointer hover:shadow-lg transition-shadow duration-300 transform hover:-translate-y-1">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="text-lg font-semibold text-gray-800 dark:text-white flex items-center">
                      {getEventIcon(event.type)}
                      <span className="ml-2">{event.title}</span>
                    </h3>
                    {event.where && (
                      <div className="flex items-center justify-end text-sm text-gray-600 dark:text-gray-400 mb-2">
                        <MapPin size={16} className="mr-1" />
                        <span>{event.where}</span>
                      </div>
                    )}
                  </div>
                  <span className="text-sm text-gray-600 dark:text-gray-300">{event.date}</span>
                  {expandedEvents.includes(event.id) ? (
                    <div>
                      <p className="pt-4 text-gray-600 dark:text-gray-300 mb-2">{event.description}</p>
                    </div>
                  ) : null
                  }
                </div>
              </div>
              <div className="md:w-2/12 flex justify-center items-center relative">
                <div className="w-4 h-4 bg-blue-500 rounded-full z-10 hidden md:block"></div>
                {!isMobile && (
                  <div 
                    className={`absolute w-1/2 h-0.5 bg-blue-500 ${index % 2 === 0 ? 'left-1/2' : 'right-1/2'}`}
                  ></div>
                )}
              </div>
              {isMobile ? <div className="w-full"></div> : <div className="w-5/12"></div>}
            </div>
          ))}
        </div>

        {filteredEvents.length > 5 && (
          <div className="flex justify-center mt-8">
            <button 
              onClick={() => setShowAllEvents(!showAllEvents)}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              {showAllEvents ? 'Show Less' : 'Show More'}
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Timeline;