import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface CustomLinkProps {
  to: string;
  children: React.ReactNode;
  external?: boolean;
  download?: string;
}

const CustomLink: React.FC<CustomLinkProps> = ({ to, children, external = false, download= null}) => {
  const location = useLocation(); 
  const baseClasses = "relative inline-block text-blue-600 dark:text-blue-400 transition-colors duration-300";
  const hoverClasses = "hover:text-blue-800 dark:hover:text-blue-300";
  const afterClasses = "after:content-[''] after:absolute after:w-full after:h-0.5 after:bg-current after:left-0 after:bottom-0 after:transform after:scale-x-0 after:transition-transform after:duration-300 hover:after:scale-x-100";
 
  const handleDownload = (url: string, filename: string) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const currentPath = location.pathname + location.search + location.hash;

  if (external) {
    return (
      <a href={download ? currentPath : to} onClick={() => download ? handleDownload(to, download) : null} target="_blank" rel="noopener noreferrer" className={`${baseClasses} ${hoverClasses} ${afterClasses}`}>
        {children}
      </a>
    );
  }

  return (
    <Link to={download ? currentPath : to} onClick={() => download ? handleDownload(to, download) : null} className={`${baseClasses} ${hoverClasses} ${afterClasses}`}>
      {children}
    </Link>
  );
};

export default CustomLink;