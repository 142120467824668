import React from 'react';
import { Mail } from 'lucide-react';
import { useLanguage } from '../LanguageContext';

const ContactMe = () => {
  const { t } = useLanguage(); // Use the custom hook

  const handleEmailClick = () => {
    window.location.href = 'mailto:personal@pfjacobs.com';
  };

  return (
    <section className="py-16 bg-gray-50 dark:bg-gray-900 transition-colors duration-300">
      <div className="container mx-auto px-4">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-4 text-gray-800 dark:text-white transition-colors duration-300">{t("homepage.contact.title")}</h2>
        <p className="text-center text-lg mb-8 text-gray-600 dark:text-gray-300 transition-colors duration-300">
          {t('homepage.contact.description')}
        </p>
        <div className="flex flex-col items-center">
          <button
            onClick={handleEmailClick}
            className="inline-flex items-center px-6 py-3 border border-transparent text-lg font-medium rounded-md shadow-sm text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 dark:bg-blue-500 dark:hover:bg-blue-600 transition-colors duration-300"
          >
            <Mail className="w-6 h-6 mr-2" />
            {t('homepage.contact.button')}
          </button>
        </div>
      </div>
    </section>
  );
};

export default ContactMe;